import React from 'react'
import { Spin, Alert } from 'antd'

export default function Loading(props) {
  if (props.error) {
    return <Alert
      message="Failed to load page"
      description="Unfortunately, the page you requested failed to load. Please check your internet connection."
      type="error"
      showIcon
    />
  } else if (props.pastDelay) {
    return (
      <center>
        <Spin size="large"/>
      </center>
    )
  } else {
    return null
  }
}