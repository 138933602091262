import { Component } from 'react'

import { withRouter } from 'react-router'

class Beta extends Component {
  UNSAFE_componentWillMount = () => {
    if (this.props.match.params.status === 'true') {
      this.props.setBeta(true)
    } else {
      this.props.setBeta(false)
    }

    this.props.history.go(-1)
  }

  render() {
    return 'Waiting for redirect...'
  }
}

export default withRouter(Beta)
