import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/browser'

import App from './App'
import * as serviceWorker from './serviceWorker'

import { Auth0Provider } from './Auth0Provider'
import config from './authConfig.json'
import history from './utils/history'

import { BetaContext } from './Context/BetaContext'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://13efbf3b34cb44deb8831526b99273ca@sentry.io/1811162',
    environment: process.env.NODE_ENV,
  })
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = async (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    audience={config.audience}
    onRedirectCallback={onRedirectCallback}
    advancedOptions={{
      defaultScope: config.defaultScope,
    }}
  >
    <BetaContext.Provider value={{ beta: true }}>
      <App />
    </BetaContext.Provider>
  </Auth0Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
