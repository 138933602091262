import { lazy } from 'react'

import ReadPointId from './ReadPointId'
import CollectionId from './CollectionId'
import ScriptId from './ScriptId'
import ApplicationId from './ApplicationId'

export const ROUTES = [
  {
    path: '/',
    name: 'Tools for RAIN RFID and EPCIS repository',
    inMenu: false,
    breadcrumb: '🏠 Home',
    description:
      'Mimasu is an online platform that provides tools for RAIN RFID and is an online, hosted EPCIS repository.',
    component: lazy(() => import('../Pages/Home/Home')),
  },
  {
    path: '/rain-rfid-as-a-service',
    name: 'RAIN RFID-as-a-service',
    inMenu: false,
    breadcrumb: 'RAIN RFID-as-a-service',
    description:
      'A new platform to remove all complexity from RAIN RFID deployments, allowing you to focus on the business application.',
    component: lazy(() => import('../Pages/Home/Landing')),
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    inMenu: false,
    auth: true,
    breadcrumb: 'Onboarding',
    component: lazy(() => import('../Pages/Onboarding/Onboarding')),
  },
  {
    path: '/intro',
    name: 'RAIN Introduction',
    breadcrumb: 'RAIN introduction',
    description:
      'An introduction on the RAIN RFID (UHF RFID, GS1 EPC & ISO 18000-6C) technology.',
    component: lazy(() => import('../Pages/RainIntroduction/RainIntroduction')),
  },
  {
    path: '/tag-encoding',
    name: 'Tag Encoding',
    breadcrumb: 'Tag Encoding',
    routes: [
      {
        path: '/tag-encoding/tdt',
        name: 'Tag Data Translation',
        breadcrumb: 'Tag Data Translation',
        description:
          'An online tool to translate from EPC to binary/hexdecimal encoding, and back. Supports SGTIN, SSCC, SGLN, GRAI, etc.',
        component: lazy(() => import('../Pages/TagEncoding/Tdt/Tdt')),
      },
      {
        path: '/tag-encoding/tag-security',
        name: 'Tag Security',
        breadcrumb: 'Tag Security',
        description:
          'A methodology to generate unique, secure passwords for RAIN RFID tags.',
        component: lazy(() =>
          import('../Pages/TagEncoding/TagSecurity/TagSecurity')
        ),
      },
    ],
  },
  {
    path: '/epcis-repository',
    name: 'EPCIS Repository',
    breadcrumb: 'EPCIS Repository',
    routes: [
      {
        path: '/epcis-repository/overview',
        name: 'EPCIS Repository Overview',
        breadcrumb: 'Overview',
        description:
          'An online, hosted EPCIS repository, supporting the EPCIS 1.2 standard. Contains experimental features for JSON(-LD)/REST support.',
        component: lazy(() =>
          import('../Pages/EpcisRepository/EpcisRepositoryHome')
        ),
      },
      {
        path: '/epcis-repository/collections',
        name: 'EPCIS Repository Collections',
        breadcrumb: 'Collections',
        auth: true,
        component: lazy(() =>
          import('../Pages/EpcisRepository/Collection/EpcisCollectionHome')
        ),
      },
      {
        path: '/epcis-repository/collections/:collectionId',
        name: 'EPCIS Repository Collection',
        breadcrumb: CollectionId,
        auth: true,
        inMenu: false,
        component: lazy(() =>
          import('../Pages/EpcisRepository/Collection/EpcisCollection')
        ),
      },
      {
        path: '/epcis-repository/query',
        name: 'EPCIS Repository Query',
        breadcrumb: 'Query',
        auth: true,
        component: lazy(() =>
          import('../Pages/EpcisRepository/Query/QueryHome')
        ),
      },
      {
        path: '/epcis-repository/status',
        name: 'EPCIS Repository Status',
        breadcrumb: 'Status',
        auth: true,
        component: lazy(() =>
          import('../Pages/EpcisRepository/Status/StatusHome')
        ),
      },
    ],
  },
  {
    path: '/epcis',
    name: 'EPCIS Tools',
    breadcrumb: 'EPCIS Tools',
    routes: [
      {
        path: '/epcis/validation',
        name: 'EPCIS Validate XML',
        breadcrumb: 'Validate XML',
        description:
          'Validate any EPCIS XML Document against the EPCIS 1.2 standard.',
        component: lazy(() => import('../Pages/Epcis/EpcisValidation')),
      },
      {
        path: '/epcis/xmljson',
        name: 'EPCIS XML to JSON',
        breadcrumb: 'XML to JSON',
        description: 'Convert any EPCIS XML Document to JSON(-LD).',
        component: lazy(() => import('../Pages/Epcis/EpcisXmlToJson')),
      },
      {
        path: '/epcis/queryexternal',
        name: 'EPCIS Query External Repository',
        breadcrumb: 'Query External Repository',
        description:
          'Query any EPCIS repository, and view the results in real-time.',
        component: lazy(() =>
          import('../Pages/Epcis/EpcisQueryExternalRepository')
        ),
      },
    ],
  },
  {
    path: '/readers',
    name: 'Readers',
    breadcrumb: 'Readers',
    auth: true,
    beta: true,
    component: lazy(() => import('../Pages/Reader/ReaderHome.js')),
    inMenu: true,
  },
  {
    path: '/read-points',
    name: 'Read points',
    breadcrumb: 'Read points',
    auth: true,
    beta: true,
    component: lazy(() => import('../Pages/ReadPoint/ReadPointHome.js')),
    inMenu: true,
    routes: [
      {
        path: '/read-points/:readPointId',
        breadcrumb: ReadPointId,
        auth: true,
        beta: true,
        inMenu: false,
      },
      {
        path: '/read-points/:readPointId/settings',
        name: 'Settings for read point',
        breadcrumb: 'Settings',
        auth: true,
        beta: true,
        component: lazy(() =>
          import('../Pages/ReadPoint/ReadPointSettings.js')
        ),
        inMenu: false,
      },
      {
        path: '/read-points/:readPointId/observations',
        name: 'Observations for read point',
        breadcrumb: 'Observations',
        auth: true,
        beta: true,
        component: lazy(() =>
          import('../Pages/ReadPoint/ReadPointObservations.js')
        ),
        inMenu: false,
      },
      {
        path: '/read-points/:readPointId/actions',
        name: 'Actions for read point',
        breadcrumb: 'Actions',
        auth: true,
        beta: true,
        component: lazy(() => import('../Pages/ReadPoint/ReadPointActions.js')),
        inMenu: false,
      },
      {
        path: '/read-points/:readPointId/application',
        name: 'Application for read point',
        breadcrumb: 'Application',
        auth: true,
        beta: true,
        component: lazy(() =>
          import('../Pages/ReadPoint/ReadPointApplication.js')
        ),
        inMenu: false,
      },
    ],
  },
  {
    path: '/scripts',
    name: 'Scripts',
    breadcrumb: 'Scripts',
    auth: true,
    beta: true,
    component: lazy(() => import('../Pages/Script/ScriptHome.js')),
    inMenu: true,
    routes: [
      {
        path: '/scripts/:scriptId',
        breadcrumb: ScriptId,
        auth: true,
        beta: true,
        inMenu: false,
      },
      {
        path: '/scripts/:scriptId/edit',
        name: 'Edit a Script',
        breadcrumb: 'Edit',
        auth: true,
        beta: true,
        component: lazy(() => import('../Pages/Script/ScriptEdit.js')),
        inMenu: false,
      },
    ],
  },
  {
    path: '/applications',
    name: 'Applications',
    breadcrumb: 'Applications',
    auth: true,
    beta: true,
    component: lazy(() => import('../Pages/Application/ApplicationHome.js')),
    inMenu: true,
    routes: [
      {
        path: '/applications/:applicationId',
        breadcrumb: ApplicationId,
        auth: true,
        beta: true,
        inMenu: false,
      },
      {
        path: '/applications/:applicationId/edit',
        name: 'Edit an Application',
        breadcrumb: 'Edit',
        auth: true,
        beta: true,
        component: lazy(() =>
          import('../Pages/Application/ApplicationEdit.js')
        ),
        inMenu: false,
      },
    ],
  },
  {
    path: '/settings',
    name: 'Settings',
    breadcrumb: 'Settings',
    auth: true,
    beta: true,
    component: lazy(() => import('../Pages/Setting/SettingHome.js')),
    inMenu: false,
  },
  {
    path: '/contact',
    name: 'Contact',
    breadcrumb: 'Contact',
    component: lazy(() => import('../Pages/Contact/Contact')),
  },
  {
    path: '/api-tokens',
    name: 'API Tokens',
    breadcrumb: 'API Tokens',
    auth: true,
    component: lazy(() => import('../Pages/Profile/ApiTokens')),
    inMenu: false,
  },
]
