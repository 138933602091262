import React, { Component, Suspense } from 'react'

import { Router } from 'react-router-dom'

import * as ReactGA from 'react-ga' // different notation because of bug in package, https://github.com/react-ga/react-ga/issues/165

import { Route, Redirect, Switch } from 'react-router-dom'

import history from './utils/history'

import Http404 from './Pages/Http404'

import Loading from './Components/Loading'
import Beta from './Components/Beta'
import Intermediate from './Components/Intermediate'

import * as constRoutes from './Routes/constRoutes'

import { message } from 'antd'

// only enable Google analytics on production
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-103559122-1', {
    gaOptions: {
      anonymize_ip: true,
    },
  })
  ReactGA.pageview(window.location.pathname + window.location.search)
}

class App extends Component {
  constructor(props) {
    super(props)

    message.config({
      top: 72,
      duration: 2,
      maxCount: 3,
    })
  }

  setBeta = (status) => {
    if (status) {
      localStorage.setItem('beta', 'true')
    } else {
      localStorage.removeItem('beta')
    }
  }

  getRoute = (currentRoute, allRoutes, match) => {
    return (
      <Intermediate
        currentRoute={currentRoute}
        routes={allRoutes}
        match={match}
      >
        <currentRoute.component />
      </Intermediate>
    )
  }

  getRoutes = (currentRoutes, allRoutes) => {
    return currentRoutes.map((route, i) => {
      var routesElements = []

      if (route.redirectTo) {
        routesElements.push(
          <Redirect
            exact={true}
            key={route.path}
            path={route.path}
            to={route.redirectTo}
          />
        )
      } else {
        if (route.path) {
          if (route.component) {
            routesElements.push(
              <Route
                exact={true}
                key={route.path}
                path={route.path}
                render={(match) => this.getRoute(route, allRoutes, match.match)}
              />
            )
          } else {
            routesElements.push(
              <Redirect
                exact={true}
                key={route.path}
                path={route.path}
                to={'/'}
              />
            )
          }
        }

        if (route.routes) {
          routesElements.push(this.getRoutes(route.routes, allRoutes))
        }
      }

      return routesElements
    })
  }

  render() {
    return (
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          <Switch>
            {this.getRoutes(constRoutes.ROUTES, constRoutes.ROUTES)}
            <Route
              exact
              path="/callback"
              render={(props) => {
                this.handleAuthentication(props)
                return <Redirect to="/" />
              }}
            />
            <Route
              exact
              path="/beta/:status"
              render={() => <Beta setBeta={this.setBeta} />}
            />
            <Route exact component={Http404} />
          </Switch>
        </Suspense>
      </Router>
    )
  }
}

export default App
