import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import { message } from 'antd'

import * as serviceGateway from './Api/gateway/index'
import * as TenantApi from './Api/Tenant'

import OpenAPIClientAxios from 'openapi-client-axios'
const openApiDefinition = require('./OpenApi/openapi.json')

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [token, setToken] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [tenant, setTenant] = useState()
  const [tenants, setTenants] = useState([])
  const [beta, setBeta] = useState(false)
  const [api, setApi] = useState()

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)

        const token = await auth0FromHook.getTokenSilently()
        setToken(token)

        await setupApi(token)

        await getTenantsFromServer()
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (localStorage.getItem('beta') === 'true') {
      setBeta(true)
    } else {
      setBeta(false)
    }
  }, [])

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    const token = await auth0Client.getTokenSilently()

    await setupApi(token)

    await getTenantsFromServer()

    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
    setToken(token)
  }

  const getApiAuthorization = (security, accessToken) => {
    switch (security.id) {
      case 'oauth2':
        return Promise.resolve({ token: accessToken })
      default:
        throw new Error(`Unknown security type '${security.id}'`)
    }
  }

  const setupApi = async (accessToken) => {
    // old API
    serviceGateway.init({
      url: '',
      getAuthorization: (security) =>
        getApiAuthorization(security, accessToken),
    })

    // new API
    var config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    const api = new OpenAPIClientAxios({
      definition: openApiDefinition,
      axiosConfigDefaults: config,
    })

    setApi(api)
  }

  const getTenantsFromServer = async () => {
    var result = await TenantApi.ListTenants()

    if (!result.error) {
      var tenants = result.data
      var tenant = result.data ? result.data[0] : null

      setTenant(tenant)
      setTenants(tenants)
    } else {
      console.error(`Error in API: ${result.data}`)

      message.error('Error in obtaining tenants from server')

      return null
    }
  }

  const switchTenant = (urlName) => {
    var newTenant = tenants.find((tenant) => tenant.urlName === urlName)

    if (newTenant) {
      setTenant(newTenant)
    }
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        token,
        tenant,
        tenants,
        loading,
        beta,
        api,
        handleRedirectCallback,
        switchTenant,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
