import React from 'react'
import { Row, Col, Typography } from 'antd'
import { Emoji } from 'emoji-mart'

const { Text } = Typography

export const Tenant = (props) => {
  const { tenant } = props

  return (
    <Row align="middle">
      <Col style={{ paddingRight: 12 }}>
        <Row align="middle" style={{ height: 32, minWidth: 32 }}>
          {tenant.emoji && <Emoji emoji={tenant.emoji} set="apple" size={32} />}
        </Row>
      </Col>
      <Col flex="auto">
        <Row>{tenant.fullName}</Row>
        <Row>
          <small style={{ lineHeight: '1.25em' }}>
            <Text type="secondary">Free plan</Text>
          </small>
        </Row>
      </Col>
    </Row>
  )
}
