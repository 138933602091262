import React, { Component } from 'react'
import { Spin, Typography } from 'antd'
import { NavLink } from 'react-router-dom'

import { Auth0Context } from '../Auth0Provider'

import * as CollectionApi from '../Api/Collection'

const { Text } = Typography

export default class CollectionId extends Component {
  static contextType = Auth0Context

  constructor(props, context) {
    super(props, context)
    this.state = {
      tenantId: this.context.tenant.tenantId,
    }
  }

  async componentDidMount() {
    const { tenant } = this.context
    const { match } = this.props

    var result = await CollectionApi.GetCollection(
      tenant.tenantId,
      match.params.collectionId
    )

    if (!result.error) {
      this.setState({
        name: <NavLink to={match.url}>{result.data.name}</NavLink>,
      })
    } else {
      console.log(`Error in API: ${result.data}`)
      this.setState({
        name: <Text type="danger">Not found</Text>,
      })
    }
  }

  componentDidUpdate() {
    if (this.state.tenantId !== this.context.tenant.tenantId) {
      this.setState({ tenantId: this.context.tenant.tenantId })

      this.componentDidMount()
    }
  }

  render() {
    if (this.state.name) {
      return this.state.name
    } else {
      return <Spin size="small" />
    }
  }
}
