// Auto-generated, edits will be overwritten
const spec = {
  host: '',
  //schemes: ['http'],
  //basePath: 'localhost:3000',
  contentTypes: [],
  accepts: ['application/json'],
  securityDefinitions: {
    oauth2: {
      name: 'Authorization',
      in: 'header',
      type: 'oauth2',
      description:
        'Add the token together with the text Bearer, followed by a space.',
    },
  },
}
export default spec
