/** @module Collection */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {string} tenantId 
 * @return {Promise<module:types.EpcisEventCollection[]>} Success
 */
export function ListCollections(tenantId) {
  const parameters = {
    path: {
      tenantId
    }
  }
  return gateway.request(ListCollectionsOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {object} options Optional options
 * @param {string} [options.name] 
 * @return {Promise<module:types.EpcisEventCollection>} Success
 */
export function CreateCollection(tenantId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId
    },
    query: {
      name: options.name
    }
  }
  return gateway.request(CreateCollectionOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {string} collectionId 
 * @return {Promise<module:types.EpcisEventCollection>} Success
 */
export function GetCollection(tenantId, collectionId) {
  const parameters = {
    path: {
      tenantId,
      collectionId
    }
  }
  return gateway.request(GetCollectionOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {string} collectionId 
 * @param {object} options Optional options
 * @param {module:types.EpcisEventType[]} [options.body] 
 * @return {Promise<object>} Success
 */
export function UpdateCollection(tenantId, collectionId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
      collectionId
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(UpdateCollectionOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {string} collectionId 
 * @return {Promise<object>} Success
 */
export function DeleteCollection(tenantId, collectionId) {
  const parameters = {
    path: {
      tenantId,
      collectionId
    }
  }
  return gateway.request(DeleteCollectionOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {object} options Optional options
 * @param {string} [options.name] 
 * @param {string} [options.file] 
 * @return {Promise<module:types.EpcisEventCollection>} Success
 */
export function CreateCollectionWithFile(tenantId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId
    },
    query: {
      name: options.name
    },
    formData: {
      file: options.file
    }
  }
  return gateway.request(CreateCollectionWithFileOperation, parameters)
}

const ListCollectionsOperation = {
  path: '/api/{tenantId}/collections/v1',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed', 'capture:epcis']
    }
  ]
}

const CreateCollectionOperation = {
  path: '/api/{tenantId}/collections/v1',
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed', 'capture:epcis']
    }
  ]
}

const GetCollectionOperation = {
  path: '/api/{tenantId}/collections/v1/{collectionId}',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed', 'capture:epcis']
    }
  ]
}

const UpdateCollectionOperation = {
  path: '/api/{tenantId}/collections/v1/{collectionId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'patch',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed', 'capture:epcis']
    }
  ]
}

const DeleteCollectionOperation = {
  path: '/api/{tenantId}/collections/v1/{collectionId}',
  method: 'delete',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed', 'capture:epcis']
    }
  ]
}

const CreateCollectionWithFileOperation = {
  path: '/api/{tenantId}/collections/v1/upload',
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed', 'capture:epcis']
    }
  ]
}
