import React, { Component } from 'react'
import { Layout, Alert } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'
import Media from 'react-media'

import { Redirect } from 'react-router-dom'

import { Login } from './Login'
import MimasuMenuWithRouter from './MimasuMenuWithRouter'
import LazyLoadingErrorBoundary from './LazyLoadingErrorBoundary'
import Breadcrumbs from './Breadcrumbs'

import { Helmet } from 'react-helmet'

import { Auth0Context } from '../Auth0Provider'

const { Header, Content } = Layout

export default class Intermediate extends Component {
  static contextType = Auth0Context

  constructor(props) {
    super(props)

    this.state = {
      buttons: [],
    }
  }

  getContent = () => {
    const { currentRoute, children, match } = this.props
    const { isAuthenticated, beta } = this.context

    if (currentRoute.beta && !beta) {
      return this.beta()
    } else if (currentRoute.auth && !isAuthenticated) {
      return this.notAuthenticated()
    } else {
      // TODO: check if this user has access to this tenant

      try {
        const childrenResult = React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            setButtons: this.setButtons,
            params: match.params,
          })
        })

        return childrenResult
      } catch (error) {
        console.error(error)

        return (
          <Alert
            message="Error in loading page, refresh"
            description="..."
            type="error"
            showIcon
          />
        )
      }
    }
  }

  beta = () => {
    return (
      <Alert
        message="In Bèta"
        description="This feature is still in bèta and not yet general availble (GA)."
        type="warning"
        showIcon
      />
    )
  }

  noAccess = () => {
    return (
      <Alert
        message="You do not have access to this tenant"
        description="..."
        type="warning"
        showIcon
      />
    )
  }

  notAuthenticated = () => {
    return (
      <Alert
        message="This page requires authentication"
        description="You are currently not logged-in. Please tap the button in the right-top to log-in, or create an account."
        type="info"
        showIcon
      />
    )
  }

  setButtons = (buttons) => {
    this.setState({
      buttons: buttons,
    })
  }

  render() {
    const { currentRoute, routes } = this.props
    const { isAuthenticated, loading, tenants } = this.context

    var padding = ''
    if (currentRoute.path !== '/rain-rfid-as-a-service') {
      padding = '0px 24px 24px 24px'
    }

    if (loading) {
      return <LoadingOutlined />
    } else {
      return (
        <Layout>
          {isAuthenticated && (!tenants || tenants.length === 0) && (
            <Redirect to="/onboarding" />
          )}
          <Helmet>
            {<title>Mimasu | {currentRoute.name}</title>}
            {currentRoute.description && (
              <meta name="description" content={currentRoute.description} />
            )}
            <link
              rel="canonical"
              href={`https://www.mimasu.io${currentRoute.path}`}
            />
          </Helmet>
          <Header style={{ padding: '0px', backgroundColor: '#5E81AC' }}>
            <div
              style={{
                color: '#fff',
                margin: '0px 24px 0px 24px',
                padding: '0px',
                fontSize: '24px',
                float: 'left',
              }}
            >
              <Link to="/" style={{ color: 'white' }}>
                Mimasu
              </Link>
            </div>
            <div
              style={{
                color: '#fff',
                margin: '0px 24px 0px 24px',
                float: 'right',
              }}
            >
              <Login />
            </div>
            <Media query="(max-width: 991px)">
              {(matches) => (
                <MimasuMenuWithRouter
                  isAuthenticated={isAuthenticated}
                  routes={routes}
                  currentRoute={currentRoute}
                  mobile={matches}
                />
              )}
            </Media>
          </Header>
          <Breadcrumbs buttons={this.state.buttons} />
          <Content>
            <div
              style={{
                padding,
                backgroundColor: 'white',
                minHeight: 680,
              }}
            >
              <LazyLoadingErrorBoundary>
                {this.getContent()}
              </LazyLoadingErrorBoundary>
            </div>
          </Content>
        </Layout>
      )
    }
  }
}
