import React, { Component } from 'react'
import { matchPath, withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import humanizeString from 'humanize-string'
import { Breadcrumb, PageHeader } from 'antd'

import * as constRoutes from '../Routes/constRoutes'

const DEFAULT_MATCH_OPTIONS = { exact: true }
const NO_BREADCRUMB = 'NO_BREADCRUMB'

class Breadcrumbs extends Component {
  renderBreadcrumb = ({
    component,
    breadcrumb,
    match,
    location,
    ...others
  }) => {
    if (typeof breadcrumb === 'function') {
      const Bla = breadcrumb

      return <Bla match={match} />
    }
    if (component === undefined) {
      return breadcrumb
    }
    return <NavLink to={match.url}>{breadcrumb}</NavLink>
  }

  getDefaultBreadcrumb = ({ pathSection, currentSection, location }) => {
    const match = matchPath(pathSection, {
      ...DEFAULT_MATCH_OPTIONS,
      path: pathSection,
    })

    return this.renderBreadcrumb({
      breadcrumb: humanizeString(currentSection),
      match,
      location,
    })
  }

  getBreadcrumb = ({
    currentSection,
    disableDefaults,
    excludePaths,
    location,
    pathSection,
    routes,
  }) => {
    let breadcrumb

    // Check the optional `exludePaths` option in `options` to see if the
    // current path should not include a breadcrumb.
    if (excludePaths && excludePaths.includes(pathSection)) {
      return NO_BREADCRUMB
    }

    routes.some(
      ({
        breadcrumb: userProvidedBreadcrumb,
        matchOptions,
        path,
        ...others
      }) => {
        if (!path) {
          throw new Error(
            'withBreadcrumbs: `path` must be provided in every route object'
          )
        }

        const match = matchPath(pathSection, {
          ...(matchOptions || DEFAULT_MATCH_OPTIONS),
          path,
        })

        // If user passed breadcrumb: null OR custom match options to suppress a breadcrumb
        // we need to know NOT to add it to the matches array
        // see: `if (breadcrumb !== NO_BREADCRUMB)` below.
        if (
          (match && userProvidedBreadcrumb === null) ||
          (!match && matchOptions)
        ) {
          breadcrumb = NO_BREADCRUMB
          return true
        }

        if (match) {
          // This covers the case where a user may be extending their react-router route
          // config with breadcrumbs, but also does not want default breadcrumbs to be
          // automatically generated (opt-in).
          if (!userProvidedBreadcrumb && disableDefaults) {
            breadcrumb = NO_BREADCRUMB
            return true
          }

          breadcrumb = this.renderBreadcrumb({
            // Although we have a match, the user may be passing their react-router config object
            // which we support. The route config object may not have a `breadcrumb` param specified.
            // If this is the case, we should provide a default via `humanizeString`.
            breadcrumb:
              userProvidedBreadcrumb || this.humanizeString(currentSection),
            match,
            location,
            ...others,
          })
          return true
        }

        return false
      }
    )

    // User provided a breadcrumb prop, or we generated one via `humanize-string` above.
    if (breadcrumb) {
      return breadcrumb
    }

    // If there was no breadcrumb provided and user has disableDefaults turned on.
    if (disableDefaults) {
      return NO_BREADCRUMB
    }

    // If the above conditionals don't fire, generate a default breadcrumb based on the path.
    return this.getDefaultBreadcrumb({
      pathSection,
      // include a "Home" breadcrumb by default (can be overrode or disabled in config).
      currentSection: pathSection === '/' ? 'Home' : currentSection,
      location,
    })
  }

  getBreadcrumbs = (routes, location, options) => {
    const matches = []
    const { pathname } = location

    pathname
      .split('?')[0]
      // Remove trailing slash "/" from pathname.
      .replace(/\/$/, '')
      // Split pathname into sections.
      .split('/')
      // Reduce over the sections and call `getBreadcrumb()` for each section.
      .reduce((previousSection, currentSection) => {
        // Combine the last route section with the currentSection.
        // For example, `pathname = /1/2/3` results in match checks for
        // `/1`, `/1/2`, `/1/2/3`.
        const pathSection = !currentSection
          ? '/'
          : `${previousSection}/${currentSection}`

        const breadcrumb = this.getBreadcrumb({
          currentSection,
          location,
          pathSection,
          routes,
          ...options,
        })

        // Add the breadcrumb to the matches array
        // unless the user has explicitly passed.
        // { path: x, breadcrumb: null } to disable.
        if (breadcrumb !== NO_BREADCRUMB) {
          matches.push(breadcrumb)
        }

        return pathSection === '/' ? '' : pathSection
      }, null)

    return matches
  }

  getTitle = (routes, location, options) => {
    const { pathname } = location
    const finalPath = pathname.split('?')[0]

    for (let route of routes) {
      const path = route.path

      if (!route.path) {
        throw new Error(
          'withBreadcrumbs: `path` must be provided in every route object'
        )
      }

      const match = matchPath(finalPath, {
        ...DEFAULT_MATCH_OPTIONS,
        path,
      })

      if (match) {
        return route.name
      }
    }

    return 'No route match found'
  }

  /**
   * Takes a route array and recursively flattens it IF there are
   * nested routes in the config.
   */
  flattenRoutes = (routes) =>
    routes.reduce((arr, route) => {
      if (route.routes) {
        return arr.concat([route, ...this.flattenRoutes(route.routes)])
      }
      return arr.concat(route)
    }, [])

  render() {
    const { location, buttons } = this.props
    const options = {}
    const routes = this.flattenRoutes(constRoutes.ROUTES)

    const breadcrumbs = this.getBreadcrumbs(routes, location, options)
    const title = this.getTitle(routes, location, options)

    return (
      // not show for homepage
      location.pathname !== '/rain-rfid-as-a-service' && (
        <>
          <Breadcrumb
            style={{
              margin: '12px 24px',
            }}
          >
            {breadcrumbs.map((breadcrumb, index) => {
              return <Breadcrumb.Item key={index}>{breadcrumb}</Breadcrumb.Item>
            })}
          </Breadcrumb>
          <PageHeader
            title={<h3 style={{ paddingBottom: 0 }}>{title}</h3>}
            extra={buttons}
            style={{ paddingBottom: 8, backgroundColor: 'white' }}
          />
        </>
      )
    )
  }
}

export default withRouter(Breadcrumbs)
