import React, { Component } from 'react'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import { Dropdown, Button, Menu } from 'antd'
import { NavLink, withRouter } from 'react-router-dom'

import { Auth0Context } from '../Auth0Provider'

const SubMenu = Menu.SubMenu

class MimasuMenu extends Component {
  static contextType = Auth0Context

  getMenuItem = (item) => {
    var subMenu = false
    if (item.hasOwnProperty('routes')) {
      var subMenuItems = item.routes.filter((route) => route.inMenu !== false)
      if (subMenuItems.length) {
        subMenu = true
      }
    }

    return subMenu ? (
      <SubMenu key={item.path} title={item.breadcrumb}>
        {item.routes.map(
          (subItem) =>
            !(subItem.inMenu === false) && (
              <Menu.Item key={subItem.path}>
                <NavLink
                  to={subItem.path}
                  activeClassName="selected"
                  //style={{ color: '#5E81AC' }}
                >
                  {subItem.breadcrumb}
                </NavLink>
              </Menu.Item>
            )
        )}
      </SubMenu>
    ) : (
      <Menu.Item key={item.path}>
        <NavLink to={item.path} activeClassName="selected">
          {item.breadcrumb}
        </NavLink>
      </Menu.Item>
    )
  }

  getMenu = () => {
    var { routes, currentRoute, mobile } = this.props
    var { isAuthenticated, beta } = this.context

    var mode = 'horizontal'
    if (mobile) {
      mode = 'vertical'
    }

    return (
      <Menu
        mode={mode}
        theme={'dark'}
        style={{ lineHeight: '64px' }}
        defaultSelectedKeys={[currentRoute.path]}
        forceSubMenuRender={true}
      >
        {routes
          .filter((item) => !item.hasOwnProperty('inMenu') || item.inMenu)
          .filter((item) => !item.auth || isAuthenticated)
          .filter((item) => !item.beta || beta)
          .map((item) => this.getMenuItem(item))}
      </Menu>
    )
  }

  render() {
    var { mobile } = this.props

    if (mobile) {
      return (
        <Dropdown overlay={this.getMenu()} placement="bottomLeft">
          <Button>
            <MenuUnfoldOutlined />
          </Button>
        </Dropdown>
      )
    } else {
      return this.getMenu()
    }
  }
}

const MimasuMenuWithRouter = withRouter(MimasuMenu)

export default MimasuMenuWithRouter
