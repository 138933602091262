/** @module Tenant */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {object} options Optional options
 * @param {string} [options.userId] 
 * @return {Promise<module:types.Tenant[]>} Success
 */
export function ListTenants(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      userId: options.userId
    }
  }
  return gateway.request(ListTenantsOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.fullName] 
 * @param {string} [options.urlName] 
 * @param {string} [options.emoji] 
 * @return {Promise<module:types.Tenant>} Success
 */
export function CreateTenant(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      fullName: options.fullName,
      urlName: options.urlName,
      emoji: options.emoji
    }
  }
  return gateway.request(CreateTenantOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.urlName] 
 * @return {Promise<module:types.Tenant>} Success
 */
export function DoesUrlNameExist(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      urlName: options.urlName
    }
  }
  return gateway.request(DoesUrlNameExistOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @return {Promise<module:types.TenantSetting[]>} Success
 */
export function GetSettingsForTenant(tenantId) {
  const parameters = {
    path: {
      tenantId
    }
  }
  return gateway.request(GetSettingsForTenantOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {object} options Optional options
 * @param {module:types.TenantSetting[]} [options.body] 
 * @return {Promise<object>} Success
 */
export function SetSettingsForTenant(tenantId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(SetSettingsForTenantOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @return {Promise<module:types.TenantInvitationCode>} Success
 */
export function CreateInvitationCode(tenantId) {
  const parameters = {
    path: {
      tenantId
    }
  }
  return gateway.request(CreateInvitationCodeOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.invitationCode] 
 * @return {Promise<object>} Success
 */
export function UseInvitationCode(options) {
  if (!options) options = {}
  const parameters = {
    query: {
      invitationCode: options.invitationCode
    }
  }
  return gateway.request(UseInvitationCodeOperation, parameters)
}

const ListTenantsOperation = {
  path: '/api/tenants/v1',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['user-id-allowed']
    }
  ]
}

const CreateTenantOperation = {
  path: '/api/tenants/v1',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const DoesUrlNameExistOperation = {
  path: '/api/tenants/v1/exists',
  method: 'get',
  security: [
    {
      id: 'oauth2'
    }
  ]
}

const GetSettingsForTenantOperation = {
  path: '/api/tenants/v1/{tenantId}/settings',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}

const SetSettingsForTenantOperation = {
  path: '/api/tenants/v1/{tenantId}/settings',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json','application/xml','text/xml','application/*+xml'],
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}

const CreateInvitationCodeOperation = {
  path: '/api/tenants/v1/{tenantId}/create-invitation-code',
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}

const UseInvitationCodeOperation = {
  path: '/api/tenants/v1/use-invitation-code',
  method: 'post',
  security: [
    {
      id: 'oauth2'
    }
  ]
}
