/** @module Application */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {string} tenantId
 * @return {Promise<module:types.Application[]>} Success
 */
export function ListApplications(tenantId) {
  const parameters = {
    path: {
      tenantId,
    },
  }
  return gateway.request(ListApplicationsOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {object} options Optional options
 * @param {string} [options.name]
 * @return {Promise<module:types.Application>} Success
 */
export function CreateApplication(tenantId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
    },
    query: {
      name: options.name,
    },
  }
  return gateway.request(CreateApplicationOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} applicationId
 * @return {Promise<module:types.Application>} Success
 */
export function GetApplication(tenantId, applicationId) {
  const parameters = {
    path: {
      tenantId,
      applicationId,
    },
  }
  return gateway.request(GetApplicationOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} applicationId
 * @return {Promise<object>} Success
 */
export function DeleteApplication(tenantId, applicationId) {
  const parameters = {
    path: {
      tenantId,
      applicationId,
    },
  }
  return gateway.request(DeleteApplicationOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} applicationId
 * @param {object} options Optional options
 * @param {module:types.Application} [options.body]
 * @return {Promise<object>} Success
 */
export function UpdateApplicationCode(tenantId, applicationId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
      applicationId,
    },
    body: {
      body: options.body,
    },
  }
  return gateway.request(UpdateApplicationCodeOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} applicationId
 * @param {object} options Optional options
 * @param {module:types.Application} [options.body]
 * @return {Promise<object>} Success
 */
export function LintApplication(tenantId, applicationId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
      applicationId,
    },
    body: {
      body: options.body,
    },
  }
  return gateway.request(LintApplicationOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} applicationId
 * @param {object} options Optional options
 * @param {module:types.Application} [options.body]
 * @return {Promise<object>} Success
 */
export function CompileApplication(tenantId, applicationId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
      applicationId,
    },
    body: {
      body: options.body,
    },
  }
  return gateway.request(CompileApplicationOperation, parameters)
}

const ListApplicationsOperation = {
  path: '/api/{tenantId}/applications/v1',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const CreateApplicationOperation = {
  path: '/api/{tenantId}/applications/v1',
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const GetApplicationOperation = {
  path: '/api/{tenantId}/applications/v1/{applicationId}',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const DeleteApplicationOperation = {
  path: '/api/{tenantId}/applications/v1/{applicationId}',
  method: 'delete',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const UpdateApplicationCodeOperation = {
  path: '/api/{tenantId}/applications/v1/{applicationId}',
  contentTypes: [
    'application/json-patch+json',
    'application/json',
    'text/json',
    'application/*+json',
    'application/xml',
    'text/xml',
    'application/*+xml',
  ],
  method: 'patch',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const LintApplicationOperation = {
  path: '/api/{tenantId}/applications/v1/{applicationId}/lint',
  contentTypes: [
    'application/json-patch+json',
    'application/json',
    'text/json',
    'application/*+json',
    'application/xml',
    'text/xml',
    'application/*+xml',
  ],
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const CompileApplicationOperation = {
  path: '/api/{tenantId}/applications/v1/{applicationId}/compile',
  contentTypes: [
    'application/json-patch+json',
    'application/json',
    'text/json',
    'application/*+json',
    'application/xml',
    'text/xml',
    'application/*+xml',
  ],
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}
