import React, { useState, useEffect } from 'react'
import { Typography, Spin, message } from 'antd'

import { Tenant } from '../Shared/Tenant'

import * as TenantApi from '../Api/Tenant'

const { Paragraph, Text } = Typography

export const AddMember = (props) => {
  const [invitationCode, setInvitationCode] = useState()
  const [loading, setLoading] = useState(true)

  const { tenant } = props

  useEffect(() => {
    async function createInvitationCode() {
      var result = await TenantApi.CreateInvitationCode(tenant.tenantId)

      if (!result.error) {
        setInvitationCode(result.data.invitationCode)
        setLoading(false)
      } else {
        console.error(`Error in API: ${result.data}`)
        message.error('Error in creating invitation code.')
      }
    }

    createInvitationCode()
  }, [tenant])

  if (loading) {
    return <Spin />
  } else {
    return (
      <>
        <Paragraph>
          <Tenant tenant={tenant} />
        </Paragraph>
        <Paragraph>
          To add a new member to your tenant, share the following code with
          them. The code is valid for 72 hours.
        </Paragraph>
        <Paragraph copyable={{ text: invitationCode }}>
          <Text code style={{ fontSize: '200%', letterSpacing: '0.1em' }}>
            {invitationCode}
          </Text>
        </Paragraph>
        <Paragraph>
          The member can use the code in two ways:
          <ul>
            <li>
              If the member has not yet created an account, the code can be used
              directly after creating an account.
            </li>
            <li>
              If the member already has an account, use the 'Add tenant' option
              in the user menu.
            </li>
          </ul>
        </Paragraph>
      </>
    )
  }
}
