/** @module ReadPoint */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {string} tenantId
 * @return {Promise<module:types.ReadPoint[]>} Success
 */
export function ListReadPoints(tenantId) {
  const parameters = {
    path: {
      tenantId,
    },
  }
  return gateway.request(ListReadPointsOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {object} options Optional options
 * @param {string} [options.name]
 * @param {string} [options.uri]
 * @param {string[]} [options.scriptIds]
 * @return {Promise<module:types.ReadPoint>} Success
 */
export function CreateReadPoint(tenantId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
    },
    query: {
      name: options.name,
      uri: options.uri,
      scriptIds: gateway.formatArrayParam(
        options.scriptIds,
        'csv',
        'scriptIds'
      ),
      applicationId: options.applicationId,
    },
  }
  return gateway.request(CreateReadPointOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} readPointId
 * @return {Promise<module:types.ReadPoint>} Success
 */
export function GetReadPoint(tenantId, readPointId) {
  const parameters = {
    path: {
      tenantId,
      readPointId,
    },
  }
  return gateway.request(GetReadPointOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} readPointId
 * @param {object} options Optional options
 * @param {module:types.ReadPoint} [options.body]
 * @return {Promise<object>} Success
 */
export function UpdateReadPoint(tenantId, readPointId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
      readPointId,
    },
    body: {
      body: options.body,
    },
  }
  return gateway.request(UpdateReadPointOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} readPointId
 * @return {Promise<object>} Success
 */
export function DeleteReadPoint(tenantId, readPointId) {
  const parameters = {
    path: {
      tenantId,
      readPointId,
    },
  }
  return gateway.request(DeleteReadPointOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} readPointId
 * @return {Promise<module:types.ReadPointSetting[]>} Success
 */
export function GetSettingsForReadPoint(tenantId, readPointId) {
  const parameters = {
    path: {
      tenantId,
      readPointId,
    },
  }
  return gateway.request(GetSettingsForReadPointOperation, parameters)
}

/**
 * @param {string} tenantId
 * @param {string} readPointId
 * @param {object} options Optional options
 * @param {module:types.ReadPointSetting[]} [options.body]
 * @return {Promise<object>} Success
 */
export function SetSettingsForReadPoint(tenantId, readPointId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
      readPointId,
    },
    body: {
      body: options.body,
    },
  }
  return gateway.request(SetSettingsForReadPointOperation, parameters)
}

const ListReadPointsOperation = {
  path: '/api/{tenantId}/read-points/v1',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const CreateReadPointOperation = {
  path: '/api/{tenantId}/read-points/v1',
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const GetReadPointOperation = {
  path: '/api/{tenantId}/read-points/v1/{readPointId}',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const UpdateReadPointOperation = {
  path: '/api/{tenantId}/read-points/v1/{readPointId}',
  contentTypes: [
    'application/json-patch+json',
    'application/json',
    'text/json',
    'application/*+json',
    'application/xml',
    'text/xml',
    'application/*+xml',
  ],
  method: 'patch',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const DeleteReadPointOperation = {
  path: '/api/{tenantId}/read-points/v1/{readPointId}',
  method: 'delete',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const GetSettingsForReadPointOperation = {
  path: '/api/{tenantId}/read-points/v1/{readPointId}/settings',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}

const SetSettingsForReadPointOperation = {
  path: '/api/{tenantId}/read-points/v1/{readPointId}/settings',
  contentTypes: [
    'application/json-patch+json',
    'application/json',
    'text/json',
    'application/*+json',
    'application/xml',
    'text/xml',
    'application/*+xml',
  ],
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed'],
    },
  ],
}
