import React, { Component } from 'react'
import { Alert } from 'antd'

export default class LazyLoadingErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch = (error, errorInfo) => {
    console.error(error, errorInfo)

    this.setState({
      error,
      errorInfo,
    })

    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert
          message="Something went wrong. Please reload the page to continue."
          description={this.state.error}
          type="error"
          showIcon
        />
      )
    }

    return this.props.children
  }
}
