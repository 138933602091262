/** @module Script */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {string} tenantId 
 * @return {Promise<module:types.Script[]>} Success
 */
export function ListScripts(tenantId) {
  const parameters = {
    path: {
      tenantId
    }
  }
  return gateway.request(ListScriptsOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {object} options Optional options
 * @param {string} [options.name] 
 * @return {Promise<module:types.Script>} Success
 */
export function CreateScript(tenantId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId
    },
    query: {
      name: options.name
    }
  }
  return gateway.request(CreateScriptOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {string} scriptId 
 * @return {Promise<module:types.Script>} Success
 */
export function GetScript(tenantId, scriptId) {
  const parameters = {
    path: {
      tenantId,
      scriptId
    }
  }
  return gateway.request(GetScriptOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {string} scriptId 
 * @return {Promise<object>} Success
 */
export function DeleteScript(tenantId, scriptId) {
  const parameters = {
    path: {
      tenantId,
      scriptId
    }
  }
  return gateway.request(DeleteScriptOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {string} scriptId 
 * @param {object} options Optional options
 * @param {module:types.Script} [options.body] 
 * @return {Promise<object>} Success
 */
export function UpdateScriptCode(tenantId, scriptId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId,
      scriptId
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(UpdateScriptCodeOperation, parameters)
}

/**
 * @param {string} tenantId 
 * @param {object} options Optional options
 * @param {module:types.Script} [options.body] 
 * @return {Promise<object>} Success
 */
export function CompileScript(tenantId, options) {
  if (!options) options = {}
  const parameters = {
    path: {
      tenantId
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(CompileScriptOperation, parameters)
}

const ListScriptsOperation = {
  path: '/api/{tenantId}/scripts/v1',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}

const CreateScriptOperation = {
  path: '/api/{tenantId}/scripts/v1',
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}

const GetScriptOperation = {
  path: '/api/{tenantId}/scripts/v1/{scriptId}',
  method: 'get',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}

const DeleteScriptOperation = {
  path: '/api/{tenantId}/scripts/v1/{scriptId}',
  method: 'delete',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}

const UpdateScriptCodeOperation = {
  path: '/api/{tenantId}/scripts/v1/{scriptId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json','application/xml','text/xml','application/*+xml'],
  method: 'patch',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}

const CompileScriptOperation = {
  path: '/api/{tenantId}/scripts/v1/compile',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json','application/xml','text/xml','application/*+xml'],
  method: 'post',
  security: [
    {
      id: 'oauth2',
      scopes: ['tenant-allowed']
    }
  ]
}
