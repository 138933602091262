import React, { Component } from 'react'
import { Spin, Typography } from 'antd'

import { Auth0Context } from '../Auth0Provider'

import * as ReadPointApi from '../Api/ReadPoint'

const { Text } = Typography

export default class ReadPointId extends Component {
  static contextType = Auth0Context

  constructor(props, context) {
    super(props, context)
    this.state = {
      tenantId: this.context.tenant.tenantId,
    }
  }

  async componentDidMount() {
    const { tenant } = this.context
    const { match } = this.props

    var result = await ReadPointApi.GetReadPoint(
      tenant.tenantId,
      match.params.readPointId
    )

    if (!result.error) {
      this.setState({
        name: result.data.name,
      })
    } else {
      console.log(`Error in API: ${result.data}`)
      this.setState({
        name: <Text type="danger">Not found</Text>,
      })
    }
  }

  componentDidUpdate() {
    if (this.state.tenantId !== this.context.tenant.tenantId) {
      this.setState({ tenantId: this.context.tenant.tenantId })

      this.componentDidMount()
    }
  }

  render() {
    if (this.state.name) {
      return <span>{this.state.name}</span>
    } else {
      return <Spin size="small" />
    }
  }
}
