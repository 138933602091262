import React from 'react'
import { DownOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  Row,
  Col,
  Button,
  Avatar,
  Dropdown,
  Menu,
  Tag,
  Typography,
  Modal,
} from 'antd'
import { NavLink } from 'react-router-dom'
import { Emoji } from 'emoji-mart'

import { AddMember } from './AddMember'

import { useAuth0 } from '../Auth0Provider'

const { Text } = Typography

export const Login = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    loading,
    user,
    tenant,
    tenants,
    switchTenant,
    beta,
  } = useAuth0()

  const addMember = () => {
    const settings = {
      title: 'Add member',
      content: <AddMember tenant={tenant} />,
      icon: null,
    }

    Modal.info(settings)
  }

  const getMenu = () => {
    return (
      <Menu>
        <Menu.Item disabled={true}>Current tenant</Menu.Item>
        <Menu.Item onClick={addMember}>Add member</Menu.Item>
        <Menu.Item>
          <NavLink to={'/api-tokens'}>API tokens</NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink to={'/settings'}>Settings</NavLink>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item disabled={true}>Switch tenants</Menu.Item>
        {tenants &&
          Array.isArray(tenants) &&
          tenants.map((value, index) => (
            <Menu.Item key={index} onClick={() => switchTenant(value.urlName)}>
              <Row align="middle">
                <Col style={{ paddingRight: 12 }}>
                  <Row align="middle" style={{ height: 32, minWidth: 32 }}>
                    {value.emoji && (
                      <Emoji emoji={value.emoji} size={32} native={true} />
                    )}
                  </Row>
                </Col>
                <Col flex="auto">
                  <Row>{value.fullName}</Row>
                  <Row>
                    <small style={{ lineHeight: '1.25em' }}>
                      <Text type="secondary">Free plan</Text>
                    </small>
                  </Row>
                </Col>
                <Col style={{ paddingLeft: 12 }}>
                  <Row>
                    {tenant.urlName === value.urlName && (
                      <>
                        {' '}
                        <Tag color="green" style={{ marginRight: 0 }}>
                          active
                        </Tag>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Menu.Item>
          ))}
        <Menu.Item>
          <NavLink to={'/onboarding'}>Add tenant</NavLink>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={logout}>
          <Row>
            <Text type="secondary">Log out</Text>
          </Row>
          <Row>
            <small style={{ lineHeight: '1.25em' }}>
              <Text type="secondary">{user.name}</Text>
            </small>
          </Row>
        </Menu.Item>
        {beta && (
          <Menu.Item disabled={true}>
            <Row align="middle">
              <Col style={{ paddingRight: 12 }}>
                <Row align="middle" style={{ height: 32, minWidth: 32 }}>
                  <Emoji emoji="construction" size={32} native={true} />
                </Row>
              </Col>
              <Col flex="auto">
                <Row>Using beta</Row>
              </Col>
            </Row>
          </Menu.Item>
        )}
      </Menu>
    )
  }

  if (!isAuthenticated) {
    return <Button onClick={loginWithRedirect}>Log-in</Button>
  } else {
    if (loading || !user) {
      return <LoadingOutlined />
    } else {
      return (
        <div style={{ float: 'right' }}>
          <Dropdown
            overlay={getMenu()}
            placement="bottomRight"
            //trigger="click"
            //visible={true}
          >
            <span>
              <Avatar
                shape="square"
                size="large"
                style={{ marginRight: '6px' }}
                icon={<UserOutlined />}
                src={user.picture}
              />{' '}
              {tenant.fullName} <DownOutlined />
            </span>
          </Dropdown>
        </div>
      )
    }
  }
}
